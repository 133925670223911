import { createRouter, createWebHashHistory, RouteRecordRaw, Router, RouterOptions } from 'vue-router'
import ApiService from '@/services/api.service'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import TransmissionsCreate from '@/views/TransmissionsCreate.vue'
import TransmissionsCreateShipment from '@/views/TransmissionsCreateShipment.vue'

const Layouts = {
  AppLayout: () => import('@/views/layouts/AppLayout.vue')
}

const routes: RouteRecordRaw[] = <RouteRecordRaw[]>[
  {
    path: '/app',
    name: 'home',
    component: Layouts.AppLayout,
    children: <RouteRecordRaw[]>[
      {
        path: '',
        name: 'listTransmissions',
        component: HomeView
      },
      {
        path: 'create',
        name: 'createTransmission',
        component: TransmissionsCreate
      },
      {
        path: 'create-shipment',
        name: 'createShipmentTransmission',
        component: TransmissionsCreateShipment
      },
      {
        path: 'transmissions/:id',
        name: 'transmission',
        component: TransmissionsCreate
      },
      {
        path: 'transmissions/:cloneId/clone',
        name: 'transmissionClone',
        component: TransmissionsCreate
      }
    ]
  },
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: {
      public: true,
      onlyWhenLoggedOut: true
    }
  },
]

const router: Router = createRouter(<RouterOptions>{
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
  const loggedIn = !!ApiService.token.getToken();

  if (!isPublic && !loggedIn) {
    ApiService.unmount401Interceptor();
    return next({
      path: '/',
      query: { redirect: to.fullPath }  // Store the full path to redirect the user to after login
    });
  }
  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/app');
  }
  next();
});

export default router