import ApiService from '@/services/api.service'

class WhatsappService {

    baseUrl = '/whatsapp'

    sessionStatus() {
        return ApiService.get(`${this.baseUrl}/session/status`)
    }

    sessionConnect() {
        return ApiService.post(`${this.baseUrl}/session/connect`)
    }

    sessionQR() {
        return ApiService.get(`${this.baseUrl}/session/qr`)
    }
    
    getQrCode(){
        return ApiService.post(`${this.baseUrl}/qrcode`)
    }

    getPhonesData(){
        return ApiService.post(`${this.baseUrl}/phones-data`)
    }

}

export default new WhatsappService()