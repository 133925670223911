<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            Login
          </div>
          <div class="card-body text-left">
            <form @submit.prevent="login">
              <div class="mt-2 col">
                <label for="email">Email</label>
                <input type="email" class="form-control" id="email" v-model="data.email" required>
              </div>
              <div class="mt-2 col">
                <label for="password">Senha</label>
                <input type="password" class="form-control" id="password" v-model="data.password" required>
              </div>
              <div class="mt-2 col text-right">
                <button type="submit" class="btn btn-primary">Entrar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {reactive} from "vue";
import {authService} from "@/services/auth.service";

const data = reactive({
  email: '',
  password: ''
});

async function login(){
  await authService.login(data.email, data.password);
}

</script>