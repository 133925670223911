<template>
  <router-view/>
</template>

<style lang="scss">

  @import '~notyf/notyf.min.css';
  @import '~bootstrap/dist/css/bootstrap.min.css';

  body {
    height: 100vh;
    overflow: hidden;
  }

  #app {
    height: 100vh;
    overflow: hidden;
  }

</style>
