import router from "@/router";
import ApiService from "@/services/api.service";
import { Notyf } from 'notyf';
import {AxiosError} from "axios";

const notyf = new Notyf();


const authService = new class AuthService{
    logout(){
        ApiService.token.removeToken();
        ApiService.token.removeRefreshToken();
        ApiService.removeHeader();

        ApiService.unmount401Interceptor()
        router.push('/')
    }
    async login (email: string, password: string) {
        const requestData = {
            method: 'post',
            url: "/login",
            data: {
                email,
                password
            }
        };
        try {
            const response = await ApiService.customRequest(requestData);

            ApiService.token.saveToken(response.data.token);
            ApiService.setHeader();
            // window.location.reload();

            ApiService.mount401Interceptor();
            router.push('/app');
            return response.data.accessToken
        } catch (error: object | unknown) {
            if(error instanceof AxiosError)
                notyf.error(error?.response?.data?.message);
            else notyf.error('An error occurred. Please try again later.');
        }
    }
}


export {authService};