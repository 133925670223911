import axios from 'axios'
import { Notyf } from 'notyf'
import { TokenService } from '@/services/token.service'
import { authService } from '@/services/auth.service'
import router from '@/router'

const notyf = new Notyf();

class ApiService {

    axios = axios;
    isDev = process.env.NODE_ENV === "development";
    _401interceptor?: number;
    token = TokenService;

    constructor() {
        this.axios.defaults.baseURL = 'https://wpp.cloudcrm.tech/api';
        if(this.isDev){
            this.axios.defaults.baseURL = 'http://localhost:3006';
        }
    }

    getBaseUrl(){
        return this.axios.defaults.baseURL;
    }

    getAuthHeader(){
        return {"Authorization": `Bearer ${TokenService.getToken()}`};
    }

    setHeader() {
        axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`;
    }

    removeHeader() {
        axios.defaults.headers.common = {};
    }

    get(resource: string, config: object = {}) {
        return axios.get(resource, config)
    }

    logoutAndRedirect(){
        this.token.removeToken();
        this.token.removeRefreshToken();
        this.removeHeader();
        router.push('/');
    }

    post(resource: string, data?: object, config: object = {}) {
        return axios.post(resource, (data), config)
    }

    put(resource: string, data?: object) {
        return axios.put(resource, (data))
    }

    delete(resource: string) {
        return axios.delete(resource)
    }

    customRequest(data: object) {
        return axios(data)
    }

    mount401Interceptor() {
        this._401interceptor = axios.interceptors.response.use(
            (response) => {
                return response
            },
            async (error) => {
                // console.log(error.request.status == 401);
                if(error.request.status === 403){
                    window.location.href = '/#/';
                    throw error
                }
                if (error.request.status === 401) {
                    authService.logout();
                    throw error
                }

                if(error.request.status === 500){
                    notyf.error("Algo de errado aconteceu");
                    throw error
                }

                if(error.request.status === 0){
                    notyf.dismissAll();
                    notyf.error('Erro no servidor');
                    throw error
                }

                // If error was not 401 just reject as is
                throw error
            }
        )
    }

    unmount401Interceptor() {
        // Eject the interceptor
        if(this._401interceptor)
            axios.interceptors.response.eject(this._401interceptor)
    }

}

export default new ApiService();