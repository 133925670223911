import { createApp } from 'vue'
import { TokenService } from '@/services/token.service'
import ApiService from '@/services/api.service'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { BootstrapVue3 } from 'bootstrap-vue-3'


if (TokenService.getToken()) {
    ApiService.setHeader();
    ApiService.mount401Interceptor();
}

createApp(App).use(router).use(BootstrapVue3).mount('#app')