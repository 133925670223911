import ApiService from '@/services/api.service'

class TransmissionService {

    baseUrl = '/transmission'
    cardUrl = '/cards'
    phonesUrl = '/whatsapp/phones'
    getAll(){
        return ApiService.get(this.baseUrl);
    }

    create(data: object){
        return ApiService.post(this.baseUrl, data);
    }

    getById(id: number){
        return ApiService.get(`${this.baseUrl}/${id}`);
    }

    uploadFile(file: any){
        const formData = new FormData();
        formData.append('file', file);
        return ApiService.post('upload', formData,  {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getCardData(){
        return ApiService.get(this.cardUrl);
    }

    getPhoneList(){
        return ApiService.post(this.phonesUrl);
    }

}

export default new TransmissionService()