<template>
  <div class="row">
    <div class="col-8">
    </div>
    <div class="col-12 d-flex justify-content-between align-items-center mt-3">
      <div>
        <button class="btn btn-sm btn-success" @click="openQrCodeModal">Gerenciar Números</button>
      </div>
      <div class="d-flex">
        <div class="me-2">
          <router-link class="btn btn-sm btn-success" :to="{ name: 'createTransmission' }">Criar Transmissão</router-link>
        </div>
        <div>
          <router-link class="btn btn-sm btn-success" :to="{ name: 'createShipmentTransmission' }">Criar Remessa</router-link>
        </div>
      </div>
    </div>

    <b-modal v-if="isQrCodeModalVisible" v-model="isQrCodeModalVisible" title="Números conectados" @hide="closeQrCodeModal" no-fade>
      <div class="text-center mb-4">
        <button 
          class="btn btn-sm btn-primary mb-3" 
          @click="fetchQrCode" 
          :disabled="isFetchingQrCode">
          Conectar novo número
        </button>
        <div v-if="phones.length > 0" class="mb-3">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Telefone</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(phone, index) in phones" :key="index">
                <td>{{ phone.phone_number }}</td>
                <td>
                  <i v-if="phone.status.toLowerCase() === 'conectado'" class="fa fa-check text-success"></i>
                  <i v-else class="fa fa-times text-danger"></i>
                  {{ phone.status }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p v-else>Nenhum telefone conectado.</p>

        <img v-if="qrCode" :src="qrCode" alt="QR Code" class="img-fluid" />
        <b-spinner v-else-if="isLoadingQrCode" label="Carregando..."></b-spinner>
      </div>
    </b-modal>

    <div class="col-12 px-0 py-2">
      <div class="row pb-2"></div>
      <div class="row">
        <div class="col-md-4 mb-4" v-for="(card, index) in cards" :key="index">
          <StatsCard :title="card.title" :value="card.value" :iconClass="card.iconClass" />
        </div>
        <div class="col-12">
          <VueTable :loading="isLoading" :headers="tableHeader" :items="itens">
            <template #item-file_url="item">
              <a v-if="!!item.file_url" :href="item.file_url" target="_blank">Abrir</a>
              <span v-else>-</span>
            </template>
            <template #item-actions="item">
              <div class="col text-right py-2 d-flex justify-content-end">
                <router-link :to="{ name: 'transmission', params: { id: item.id } }" class="btn btn-sm btn-info mr-2">Detalhes</router-link>
                <router-link :to="{ name: 'transmissionClone', params: { cloneId: item.id } }" id="clonar-btn" class="btn btn-sm btn-success">Clonar</router-link>
              </div>
            </template>
            <template #item-creation_date="item">
              {{ DateTime.fromISO(item.creation_date).toLocaleString() }}
            </template>
          </VueTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import VueTable, { Header } from 'vue3-easy-data-table';
import { DateTime } from 'luxon';
import Transmission from "@/models/Transmission";
import TransmissionService from "@/services/transmission.service";
import StatsCard from './StatsCard.vue';
import whatsappService from '@/services/whatsapp.service';

const tableHeader: Header[] = [
  { text: 'id', value: 'id' },
  { text: 'Nome', value: 'name' },
  { text: 'Mensagem', value: 'body' },
  { text: 'Arquivo', value: 'file_url' },
  { text: 'data', value: 'creation_date' },
  { text: '-', value: 'actions' },
];

const itens = ref<Transmission[]>([]);
const isLoading = ref(false);
const isQrCodeModalVisible = ref(false);
const qrCode = ref<string | null>(null);
const isLoadingQrCode = ref(false);
const isFetchingQrCode = ref(false);
const phones = ref<{ phone_number: string, status: string }[]>([]);
let pollingInterval: number | null = null;

let cards = ref([
  { title: 'Total de Mensagens', value: 0, iconClass: 'fa-envelope' },
  { title: 'Mensagens de Hoje', value: 0, iconClass: 'fa-calendar-day' },
  { title: 'Transmissões Totais', value: 0, iconClass: 'fa-paper-plane' },
]);

const openQrCodeModal = async () => {
  isQrCodeModalVisible.value = true;
  await fetchPhones(); 
  startPollingPhones();
};

const fetchPhones = async () => {
  try {
    const response = await whatsappService.getPhonesData();
    phones.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar dados dos telefones:', error);
  }
};

const fetchQrCode = async () => {
  if (isFetchingQrCode.value) return;
  isFetchingQrCode.value = true;

  qrCode.value = null; // Reseta o QR Code antes de buscar
  isLoadingQrCode.value = true;

  try {
    const response = await whatsappService.getQrCode();
    qrCode.value = response.data.qrCode;
  } catch (error) {
    console.error('Erro ao buscar QR code:', error);
  } finally {
    isLoadingQrCode.value = false;
  }
};

const startPollingPhones = () => {
  if (pollingInterval) return; 

  pollingInterval = window.setInterval(() => {
    fetchPhones();
  }, 5000); 
};

const stopPollingPhones = () => {
  if (pollingInterval) {
    clearInterval(pollingInterval);
    pollingInterval = null;
  }
};

onMounted(() => {
  isLoading.value = true;
  TransmissionService.getCardData().then((response) => {
    cards.value[0].value = response.data.totalMessages;
    cards.value[1].value = response.data.totalDayMessages;
    cards.value[2].value = response.data.totalTransmissions;
    isLoading.value = false;
  }).catch(error => {
    console.error('Erro ao buscar dados dos cartões:', error);
  });

  TransmissionService.getAll().then((response) => {
    itens.value = response.data;
    isLoading.value = false;
  });
});

onUnmounted(() => {
  stopPollingPhones();
});

const closeQrCodeModal = () => {
  nextTick(() => {
    stopPollingPhones();
    qrCode.value = null;
    phones.value = [];
    isQrCodeModalVisible.value = false;
    isFetchingQrCode.value = false; // Reativa o botão ao fechar a modal
  });
};
</script>

<style lang="scss">
@import 'vue3-easy-data-table/dist/style.css';

#clonar-btn {
  margin-left: 0.5rem;
}
</style>
